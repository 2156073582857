import React from 'react';
import PropTypes from 'prop-types';
import LinkCheckbox from './LinkCheckbox.desktop';
import { isEmpty } from 'Helpers/objects';
import { noop } from 'Helpers/function';

export const CommonOption = ({
    name,
    value,
    groupName,
    isMultipleChoice,
    isApplied,
    onChange,
    appliedFiltersInfo,
    abundance,
    filterInteractionType,
    style,
    isMX
}) => {
    const id = `c-${groupName}-${value}`;
    const { formattedExactCount } = abundance;
    const abundanceLabel = isEmpty(abundance) ? '' : `(${formattedExactCount})`;

    return (<LinkCheckbox
        id={ id }
        appliedFiltersInfo={ appliedFiltersInfo }
        attribute={ groupName }
        value={ value }
        isChecked={ !!isApplied }
        labelTitle={ name }
        onTap={ onChange }
        name={ groupName }
        isMultiSelect={ isMultipleChoice }
        abundanceLabel={ abundanceLabel }
        filterInteractionType={ filterInteractionType }
        style={ style }
        isMX={ isMX }
    />);
};

CommonOption.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isMultipleChoice: PropTypes.bool,
    isApplied: PropTypes.bool,
    groupName: PropTypes.string.isRequired,
    appliedFiltersInfo: PropTypes.object,
    abundance: PropTypes.shape({
        count: PropTypes.number,
        formattedExactCount: PropTypes.string,
        roundedCount: PropTypes.number,
        formattedRoundedCount: PropTypes.string,
        suffix: PropTypes.string,
        suffixLabelTranslation: PropTypes.string
    }),
    filterInteractionType: PropTypes.string,
    style: PropTypes.object,
    isMX: PropTypes.bool
};

CommonOption.defaultProps = {
    onChange: noop,
    isMultipleChoice: false,
    isApplied: false,
    appliedFiltersInfo: {},
    abundance: {},
    filterType: '',
    isMX: false
};

export default CommonOption;
