import React from 'react';
import PropTypes from 'prop-types';
import Option from '../../components/Option.APP_TARGET';
import css from './PopularInput.APP_TARGET.scss';
import { TRACK_FILTER_TYPE, ATTR_WITHOUT_SUFFIX } from 'Constants/filters';

export class PopularInput extends React.Component {
    static propTypes = {
        updatePopular: PropTypes.bool.isRequired,
        attribute: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        label: PropTypes.string.isRequired,
        selectedValues: PropTypes.object,
        selectedFilters: PropTypes.object,
        onRangeChange: PropTypes.func.isRequired,
        rangeBounds: PropTypes.shape({
            minValue: PropTypes.number.isRequired,
            maxValue: PropTypes.number.isRequired,
            minLabel: PropTypes.string.isRequired,
            maxLabel: PropTypes.string.isRequired
        }).isRequired,
        renderOptionAs: PropTypes.oneOf(['div', 'link'])
    }

    handleTap = (minValue, maxValue, isChecked) => (meta = {}) => {
        const { onRangeChange, attribute, rangeBounds } = this.props;

        if (isChecked) {
            onRangeChange(attribute, null, TRACK_FILTER_TYPE.POPULAR_LIST, null, meta);
        }
        else {
            let nextState = null;
            const isSuffix = ATTR_WITHOUT_SUFFIX[attribute];

            /* istanbul ignore else */
            if ((maxValue !== rangeBounds?.maxValue && !isSuffix) || isSuffix) {
                nextState = nextState || {};
                nextState.max = maxValue;
            }

            if (minValue) {
                nextState = nextState || {};
                nextState.min = minValue;
            }
            onRangeChange(attribute, nextState, TRACK_FILTER_TYPE.POPULAR_LIST, true, meta);
        }
    };
    render() {
        const { attribute, options, label, selectedValues, rangeBounds, updatePopular, selectedFilters, renderOptionAs } = this.props;

        return (
            <React.Fragment>
                <div className={ css.label }>{label}</div>
                <div className={ css.popularOptions }>
                    {options
                        && options.map(({ name, abundance, range: { minValue, maxValue }}) => {
                            let isChecked = false;
                            const range = { min: minValue, max: maxValue };

                            if (updatePopular) {
                                isChecked = selectedValues
                                    && (selectedValues.min === minValue || (!selectedValues.min && minValue === rangeBounds?.minValue))
                                    && (selectedValues.max === maxValue || (!selectedValues.max && maxValue === null));
                            }

                            return (<Option
                                onTap={ this.handleTap(minValue, maxValue, isChecked) }
                                abundance={ abundance }
                                appliedFiltersInfo={ selectedFilters }
                                attribute={ attribute }
                                value={ range }
                                rangeBounds={ rangeBounds }
                                isChecked={ isChecked }
                                name={ name }
                                key={ name }
                                filterInteractionType={ TRACK_FILTER_TYPE.POPULAR_LIST }
                                renderOptionAs={ renderOptionAs }
                            />);
                        })
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default PopularInput;
