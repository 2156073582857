import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'panamera-react-ui';
import withFilterURL from 'HOCs/withFilterURL/withFilterURL';
import css from './PopularOption.scss';
import { noop } from 'Helpers/function';

class PopularOption extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageStatus: false
        };

        const imageURI = props.imageURI ? `${props.staticAssets }/${ props.imageURI.replace('.webp', '')}` : '';

        this.imageURI = {
            '1x': imageURI.replace('$width$', '1x'),
            '2x': imageURI.replace('$width$', '2x'),
            '3x': imageURI.replace('$width$', '3x')
        };
    }
    handleImageLoaded = () => {
        this.setState({ imageStatus: true });
    };

    render() {
        const {
            name,
            value,
            attribute,
            isMultiSelect,
            isChecked,
            to,
            onTap,
            onLinkCLick
        } = this.props;
        const id = `p-${attribute}-${value}`;

        return (
            <label htmlFor={ id } className={ css.option }>
                <input
                    type={ isMultiSelect ? 'checkbox' : 'radio' }
                    id={ id }
                    name={ attribute }
                    className={ css.choice }
                    value={ value }
                    checked={ isChecked }
                    onChange={ onTap } />
                <div className={ classNames(
                    css.imgContainer,
                    { [css.placeholder]: !this.state.imageStatus }
                ) }>
                    {this.imageURI && (
                        <picture>
                            <source
                                type="image/webp"
                                srcSet={ `${this.imageURI['1x']}.webp 360w,
                                        ${this.imageURI['2x']}.webp 720w,
                                        ${this.imageURI['3x']}.webp 1080w` }
                            />
                            <source
                                srcSet={ `${this.imageURI['1x']}.png 360w,
                                    ${this.imageURI['2x']}.png 720w,
                                    ${this.imageURI['3x']}.png 1080w` }
                            />
                            <img
                                className={ css.img }
                                alt={ name }
                                src={ `${this.imageURI['1x']}.png` }
                                onLoad={ this.handleImageLoaded }
                            />
                        </picture>
                    )}
                </div>
                <Link title={ attribute } to={ to } onClick={ onLinkCLick } />
            </label>
        );
    }
}

PopularOption.propTypes = {
    onTap: PropTypes.func,
    to: PropTypes.string.isRequired,
    onLinkCLick: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    imageURI: PropTypes.string.isRequired,
    isMultiSelect: PropTypes.bool,
    isChecked: PropTypes.bool,
    attribute: PropTypes.string.isRequired,
    staticAssets: PropTypes.string.isRequired
};

PopularOption.defaultProps = {
    onTap: noop,
    onLinkCLick: noop,
    isMultiSelect: false,
    isChecked: false
};

export default withFilterURL(PopularOption);
