/* global */

import React from 'react';
import PropTypes from 'prop-types';
import { css as uicss } from 'panamera-react-ui';
import classNames from 'classnames';
import css from '../../Listing.desktop.scss';
import NavigationBarPlaceholder from 'Components/NavigationBar/components/NavigationBarPlaceholder.desktop';
import NavigationBar from 'Components/NavigationBar/NavigationBar.desktop';
import NavigationBarContainer from 'Components/NavigationBar/components/NavigationBarContainer.desktop';
import ListingCarouselWrapper from '../../../ReProjects/components/Carousel/ListingCarouselWrapper';
import SeoText from 'Components/Footer/SeoText.desktop';
import { LISTING } from 'Constants/tracking';
import BannerImage from 'Components/BannerImage/BannerImage';
import { CARS_CATEGORY_BANNER_DESKTOP_SELL, CALL_CUSTOMER_CARE_BANNER_DESKTOP } from 'Constants/widgets';
import { CONFIG } from 'Constants/config';
import { CARS_CATEGORY_BANNERS } from 'Constants/banners';
import FAQ from '../../../../olxautos/components/FAQ/FAQWrapper';
import { listingFaqs } from '../../../../olxautos/constants/faq';

const { grid } = uicss;

export class ListingContent extends React.Component {
    static propTypes = {
        category: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        location: PropTypes.object.isRequired,
        isFetchingItems: PropTypes.bool,
        nav: PropTypes.node,
        listingBody: PropTypes.node,
        similarAdsBody: PropTypes.node,
        notification: PropTypes.node,
        banners: PropTypes.node,
        navigationBarEnabled: PropTypes.bool,
        showCarouselFlag: PropTypes.bool,
        gridClasses: PropTypes.string,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        offline: PropTypes.bool
    };

    static defaultProps = {
        category: {},
        isFetchingItems: false,
        nav: undefined,
        listingBody: undefined,
        similarAdsBody: undefined,
        notification: undefined,
        banners: undefined,
        navigationBarEnabled: false,
        showCarouselFlag: false,
        gridClasses: '',
        offline: false
    };

    renderNavigationBar = isFetchingItems => {
        const { category } = this.props;

        return (
            <NavigationBarContainer>
                { isFetchingItems
                    ? <NavigationBarPlaceholder />
                    : <NavigationBar category={ category } />
                }
            </NavigationBarContainer>
        );
    }

    getCarsCategoryBanner = (type = CARS_CATEGORY_BANNERS.LISTING_PAGE, offline = null) => {
        switch (type) {
            case CARS_CATEGORY_BANNERS.LISTING_PAGE:
                return <BannerImage offline={ offline } frmPage={ LISTING } widgetToFetch={ CARS_CATEGORY_BANNER_DESKTOP_SELL } />;
            case CARS_CATEGORY_BANNERS.CALL_CUSTOMER_CARE:
                return <BannerImage frmPage={ LISTING } widgetToFetch={ CALL_CUSTOMER_CARE_BANNER_DESKTOP } callCustomerCareBanner={ true } />;
            default:
                return null;
        }
    };

    render() {
        const {
            nav,
            banners,
            category,
            location,
            listingBody,
            similarAdsBody,
            gridClasses,
            notification,
            isFetchingItems,
            showCarouselFlag,
            navigationBarEnabled,
            offline,
            config
        } = this.props;
        const { pathname } = location;

        const isCarsCategoryBannersEnabled = config.get(CONFIG.CARS_CATEGORY_BANNERS, CONFIG.ENABLED);
        const carsCategoryBannersCategoryIds = config.get(CONFIG.CARS_CATEGORY_BANNERS, CONFIG.CATEGORIES_IDS);
        const showCarsCategoryBanners = isCarsCategoryBannersEnabled && carsCategoryBannersCategoryIds?.includes(category?.id);

        const isCustomerCareBannersEnabled = config.get(CONFIG.CUSTOMER_CARE_BANNERS, CONFIG.ENABLED);
        const customerCareBannersCategoryIds = config.get(CONFIG.CUSTOMER_CARE_BANNERS, CONFIG.CATEGORIES_IDS);
        const showCustomerCareBanners = isCustomerCareBannersEnabled && customerCareBannersCategoryIds?.includes(category?.id);

        const isListingFaqEnabled = config.get(CONFIG.SEO, CONFIG.LISTING_FAQ, CONFIG.ENABLED);
        const listingFaqCategoryIds = config.get(CONFIG.SEO, CONFIG.LISTING_FAQ, CONFIG.CATEGORY_IDS);
        const showListingFaq = isListingFaqEnabled && listingFaqCategoryIds?.includes(category?.id);

        return (
            <section className={ css.listing }>
                <div className={ classNames(grid.row, { [css.container]: pathname === '/' }) }>
                    { showCarsCategoryBanners && this.getCarsCategoryBanner(CARS_CATEGORY_BANNERS.LISTING_PAGE, offline) }
                    <div className={ gridClasses }>
                        { nav }
                        { notification }
                        { banners }
                        <div style={ { display: 'flex' } }>
                            <div>
                                { showCustomerCareBanners && this.getCarsCategoryBanner(CARS_CATEGORY_BANNERS.CALL_CUSTOMER_CARE) }
                                { this.renderNavigationBar(isFetchingItems) }
                            </div>
                            <div className={ navigationBarEnabled ? css.withNavigation : css.withOutNavigation }>
                                {
                                    showCarouselFlag
                                    && <ListingCarouselWrapper
                                        carouselApiRequestPath={ category && category.category_banner && category.category_banner.request_path }
                                    />
                                }
                                { listingBody }
                                { similarAdsBody }
                                <SeoText />

                                { showListingFaq && <FAQ faqs={ listingFaqs } shouldShowHeader={ false } isListingFaq={ true } /> }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ListingContent;

