import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PopularInput from '../Fields/PopularInput/PopularInput';
import CustomInput from '../Fields/CustomInput/CustomInput.APP_TARGET';

import { addPopularRangeOptUsed } from 'Actions/filters';
import { noop } from 'Helpers/function';

export class RangeFilter extends React.Component {
    static propTypes = {
        filter: PropTypes.shape({
            render: PropTypes.shape({
                popularConfiguration: PropTypes.object.isRequired,
                customConfiguration: PropTypes.object.isRequired
            }),
            attribute: PropTypes.string.isRequired
        }),
        selectedFilters: PropTypes.object,
        onRangeChange: PropTypes.func,
        isSliderType: PropTypes.bool.isRequired,
        popularRangeUsed: PropTypes.object,
        addPopularRangeOptUsed: PropTypes.func.isRequired,
        categoryId: PropTypes.string.isRequired
    }

    static defaultProps = {
        selectedFilters: {},
        onRangeChange: noop
    }

    onRangeChange = (attribute, value, trackFilterType, isPopular, meta) => {
        this.props.addPopularRangeOptUsed(this.props.categoryId, attribute, !!isPopular);
        this.props.onRangeChange(attribute, value, trackFilterType, meta);
    }

    render() {
        const {
            filter: {
                render: {
                    popularConfiguration,
                    customConfiguration
                },
                attribute
            },
            selectedFilters,
            isSliderType,
            popularRangeUsed
        } = this.props;
        const selectedValues = selectedFilters[attribute];
        const histogramData = customConfiguration ? customConfiguration.histogramData : [];
        let updatePopular = true;

        if (popularRangeUsed && typeof popularRangeUsed[attribute] !== 'undefined') {
            updatePopular = popularRangeUsed[attribute];
        }

        return (
            <React.Fragment>
                {popularConfiguration?.options?.length
                    && <PopularInput
                        attribute={ attribute }
                        selectedValues={ selectedValues }
                        selectedFilters={ selectedFilters }
                        onRangeChange={ this.onRangeChange }
                        label={ popularConfiguration.label }
                        options={ popularConfiguration.options }
                        rangeBounds={ customConfiguration.range }
                        updatePopular={ updatePopular }
                        renderOptionAs={ popularConfiguration?.renderOptionAs || null }
                    />
                }

                <CustomInput
                    attribute={ attribute }
                    selectedValues={ selectedValues }
                    selectedFilters={ selectedFilters }
                    onRangeChange={ this.onRangeChange }
                    isSliderType={ isSliderType }
                    histogramData={ histogramData }
                    label={ customConfiguration.label }
                    range={ customConfiguration.range }
                />
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state, props) => ({
    popularRangeUsed: state.filters.popularRangeUsed[props.categoryId]
});
export const mapDispatchToProps = ({
    addPopularRangeOptUsed: (categoryId, filterAttr, isUsed) => addPopularRangeOptUsed(categoryId, filterAttr, isUsed)
});

export default connect(mapStateToProps, mapDispatchToProps)(RangeFilter);
