import React from 'react';
import PropTypes from 'prop-types';
import SingleSelectFilter from './SingleSelectFilter';
import MultiSelectFilter from './MultiSelectFilter';
import { selectionType as selectFilterType } from 'Constants/filters';

const SelectFilter = ({
    filter: {
        render: {
            customConfiguration,
            popularConfiguration
        },
        attribute
    },
    selectedValues,
    onSelectChange,
    appliedFiltersInfo,
    isMX
}) => {
    const { selectionType } = customConfiguration;

    return (selectionType === selectFilterType.MULTIPLE)
        ? <MultiSelectFilter
            customConfiguration={ customConfiguration }
            popularConfiguration={ popularConfiguration }
            attribute={ attribute }
            appliedFiltersInfo={ appliedFiltersInfo }
            selectedValues={ selectedValues }
            onSelectChange={ onSelectChange }
            isMX={ isMX }
        />
        : <SingleSelectFilter
            customConfiguration={ customConfiguration }
            popularConfiguration={ popularConfiguration }
            attribute={ attribute }
            appliedFiltersInfo={ appliedFiltersInfo }
            selectedValues={ selectedValues }
            onSelectChange={ onSelectChange }
            isMX={ isMX }
        />;
};

SelectFilter.propTypes = {
    filter: PropTypes.shape({
        render: PropTypes.shape({
            customConfiguration: PropTypes.shape({
                selectionType: PropTypes.string.isRequired
            }).isRequired,
            popularConfiguration: PropTypes.object
        }),
        attribute: PropTypes.string.isRequired
    }),
    selectedValues: PropTypes.array,
    onSelectChange: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.object,
    isMX: PropTypes.bool
};

SelectFilter.defaultProps = {
    selectedValues: [],
    appliedFiltersInfo: {},
    isMX: false
};

export default SelectFilter;
