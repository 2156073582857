import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage as Translation } from 'react-intl';
import PropTypes from 'prop-types';
import withRouter from 'HOCs/withRouter';
import Button from 'Components/Button/Button';
import { LayoutHelper } from 'olx-autos-landing-page';

import Collapsible from 'Components/Collapsible/Collapsible';
import NestedSelect from 'Components/FiltersTanak/Fields/NestedSelect.desktop';
import RangeFilter from 'Components/FiltersTanak/components/RangeFilter';
import SelectFilter from 'Components/FiltersTanak/Fields/Select/SelectFilter';

import { fetchFiltersByCategoryId, clearFilterRecencyOrder } from 'Actions/filters';
import { addFilterPreference } from 'Actions/filtersPreferences';

import {
    getFiltersArr,
    getParsedAppliedFilters,
    getCurrentSorting
} from 'Selectors/filtersTanak';
import { getFiltersPreferences } from 'Selectors/filtersPreferences';
import { getAbundanceFilterInfo } from 'Selectors/filtersAbundance';

import { isEmpty } from 'Helpers/objects';
import { scrollToTop } from 'Helpers/page';
import { parseFiltersForTracking } from 'Helpers/filtersTanak';

import { COMPONENT_TYPE } from 'Constants/filters';
import { DEFAULT_CATEGORY } from 'Constants/categories';
import { FILTERSV2_EVENTS, SELECT_FROM } from 'Constants/tracking';

import withTrack from 'HOCs/withTrack/withTrack';

import css from './Filters.desktop.scss';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { noop } from 'Helpers/function';

export class Filters extends Component {
    static propTypes = {
        track: PropTypes.func.isRequired,
        categoryID: PropTypes.string,
        filters: PropTypes.arrayOf(PropTypes.string).isRequired,
        filtersInfoByAttr: PropTypes.object.isRequired,
        appliedFiltersInfo: PropTypes.object.isRequired,
        filtersPreferences: PropTypes.object.isRequired,
        fetchFiltersByCategoryId: PropTypes.func.isRequired,
        addFilterPreference: PropTypes.func.isRequired,
        _clearFilterRecencyOrder: PropTypes.func.isRequired,
        router: PropTypes.shape({
            push: PropTypes.func.isRequired
        }),
        buildURL: PropTypes.func.isRequired,
        config: PropTypes.object.isRequired,
        currentSorting: PropTypes.string
    }

    static defaultProps = {
        categoryID: DEFAULT_CATEGORY,
        onChange: noop,
        appliedFiltersInfo: {},
        currentSorting: '',
        _clearFilterRecencyOrder: noop
    }

    constructor(props) {
        super(props);

        this.translations = {
            filters: <Translation id="filters" />,
            clearAll: <Translation id="clearAll" />
        };
        this.isOlxMobbi = props.config.get('isOlxMobbi');
    }

    componentDidMount() {
        const isAutosPlatform = this.props.config.get('olxAutos', 'isMXCL');

        this.props.fetchFiltersByCategoryId(isAutosPlatform, this.props.categoryID);
    }

    handleChange = (...args) => {
        const meta = args[args.length - 1];
        const {
            url,
            newFilters,
            filterAttribute: select_from,
            selectedValue: chosen_option,
            filterInteractionType: filters_interaction
        } = meta;
        const {
            track,
            categoryID: category_id,
            currentSorting,
            config
        } = this.props;

        const visualizationType = config.get('visualizationType', 'desktop');

        track(FILTERSV2_EVENTS.LISTING_TAP_APPLY_FILTERS, {
            filters: parseFiltersForTracking(newFilters),
            filter_count: Object.keys(newFilters).length,
            category_id,
            select_from,
            chosen_option,
            filters_interaction,
            sorting_applied: currentSorting,
            visual_applied: visualizationType,
            ...LayoutHelper.getAcquisitionChannelTrackingValues()
        });
        scrollToTop();
        this.props.router.push(this.isOlxMobbi ? `/olxmobbi${url}` : url);
    }

    trackFilters = (eventName, extraParams = {}) => {
        this.props.track(eventName, {
            category_id: this.props.categoryID,
            select_from: SELECT_FROM.FILTER_PAGE,
            ...extraParams
        });
    }

    getFilterType = currFilter =>
        (currFilter && currFilter.render && currFilter.render.defaultComponent && currFilter.render.defaultComponent.componentType) || '';

    getTanakFilterComponent = currFilter => {
        const { filtersInfoByAttr, categoryID, appliedFiltersInfo, config } = this.props;
        const filterType = this.getFilterType(currFilter);

        switch (filterType) {
            case COMPONENT_TYPE.NESTED_SELECT: {
                const staticAssets = config.get('staticAssets');

                return (<NestedSelect
                    filter={ currFilter }
                    filtersInfo={ filtersInfoByAttr }
                    appliedFiltersInfo={ appliedFiltersInfo }
                    onChange={ this.handleChange }
                    trackFilters={ this.trackFilters }
                    staticAssets={ staticAssets }
                />);
            }

            case COMPONENT_TYPE.RANGE_INPUT:
            case COMPONENT_TYPE.RANGE_SLIDER:
                return (<RangeFilter
                    filter={ currFilter }
                    filterInfo={ filtersInfoByAttr }
                    selectedFilters={ appliedFiltersInfo }
                    onRangeChange={ this.handleChange }
                    isSliderType={ filterType === COMPONENT_TYPE.RANGE_SLIDER }
                    categoryId={ categoryID }
                />);

            case COMPONENT_TYPE.SELECT:
                return (<SelectFilter
                    filter={ currFilter }
                    filterInfo={ filtersInfoByAttr }
                    appliedFiltersInfo={ appliedFiltersInfo }
                    selectedValues={ appliedFiltersInfo[currFilter.attribute] }
                    onSelectChange={ this.handleChange }
                />);

            default:
                return null;
        }
    }

    getFilterComponent = (currFilter, { collapsed } = {}) => {
        const { name } = currFilter;
        const handleOnCollapsed = _collapsed => this.props.addFilterPreference({ [currFilter.id]: { collapsed: _collapsed }});
        const filterComponent = this.getTanakFilterComponent(currFilter);

        if (!filterComponent) {
            return null;
        }

        return (
            <Collapsible
                key={ `${currFilter.id}` }
                className={ css.filters__collapsible }
                title={ name }
                collapsedContent={ null }
                // eslint-disable-next-line react/jsx-no-bind
                onCollapsed={ handleOnCollapsed }
                collapsed={ collapsed }
            >
                { filterComponent }
            </Collapsible>
        );
    }

    getFilters = () => {
        const { filters, filtersInfoByAttr, filtersPreferences } = this.props;
        const renderedFilters = filters.reduce((acc, filter) => {
            const currentFilter = filtersInfoByAttr[filter];

            if (currentFilter && currentFilter.sectionName) {
                const tanakFilter = this.getFilterComponent(currentFilter, filtersPreferences[currentFilter.id]);

                if (tanakFilter) {
                    acc.push(tanakFilter);
                }
            }
            return acc;
        }, []);

        if (renderedFilters.length) {
            return renderedFilters;
        }
        return null;
    }

    handleClearAllFiltersClick = () => {
        const {
            track,
            categoryID: category_id,
            _clearFilterRecencyOrder
        } = this.props;

        track(FILTERSV2_EVENTS.CLEAR_ALL_FILTER, {
            category_id,
            select_from: SELECT_FROM.FILTER_PAGE,
            ...LayoutHelper.getAcquisitionChannelTrackingValues()
        });
        _clearFilterRecencyOrder();
    }

    render() {
        const { filters, appliedFiltersInfo, buildURL } = this.props;
        const renderedFilters = !isEmpty(filters) && this.getFilters();

        if (!renderedFilters) {
            return null;
        }
        return (
            <div className={ css.desktopFilters }>
                <div className={ css.desktopFilters__heading } key="filter-header">
                    <div className={ css.filterText }>{this.translations.filters}</div>
                    { !isEmpty(appliedFiltersInfo)
                        && <Button
                            data-aut-id="desktopFiltersClearAll"
                            type="tertiaryBtn"
                            size="small"
                            onClick={ this.handleClearAllFiltersClick }
                            href={ buildURL() }
                        >
                            {this.translations.clearAll}
                        </Button>
                    }
                </div>
                { renderedFilters }
            </div>
        );
    }
}
const mapStateToProps = (state, { categoryID = DEFAULT_CATEGORY, config }) => ({
    filters: getFiltersArr(state, categoryID),
    filtersInfoByAttr: getAbundanceFilterInfo(state, categoryID, config),
    appliedFiltersInfo: getParsedAppliedFilters(state, categoryID),
    filtersPreferences: getFiltersPreferences(state),
    currentSorting: getCurrentSorting(state, categoryID)
});

const mapDispatchToProps = {
    fetchFiltersByCategoryId,
    addFilterPreference,
    _clearFilterRecencyOrder: clearFilterRecencyOrder
};

export default compose(
    withRouter,
    withConfig('config'),
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(Filters);
