import React from 'react';
import PropTypes from 'prop-types';
import Option from '../../components/Option.APP_TARGET';
import css from './SingleSelectOptions.APP_TARGET.scss';
import { TRACK_FILTER_TYPE } from 'Constants/filters';

const SingleSelectOptions = ({
    values,
    label,
    attribute,
    selectedValues,
    appliedFiltersInfo,
    onSelectChange,
    isPopular
}) => {
    const filterInteractionType = isPopular ? TRACK_FILTER_TYPE.POPULAR_LIST : TRACK_FILTER_TYPE.CUSTOM_LIST;

    return (
        <React.Fragment>
            <div className={ css.label }>{label}</div>
            {values && values.map(({ value, name, icon, abundance }) => {
                const isChecked = selectedValues && selectedValues.find(item => item === value);

                const handleChange = meta => {
                    if (isChecked) {
                        onSelectChange(attribute, null, TRACK_FILTER_TYPE.CUSTOM_LIST, meta);
                    }
                    else {
                        onSelectChange(attribute, [value], TRACK_FILTER_TYPE.CUSTOM_LIST, meta);
                    }
                };

                return (
                    <Option
                        // eslint-disable-next-line react/jsx-no-bind
                        onTap={ handleChange }
                        isChecked={ isChecked }
                        attribute={ attribute }
                        name={ name }
                        icon={ icon }
                        value={ value }
                        key={ value }
                        appliedFiltersInfo={ appliedFiltersInfo }
                        abundance={ abundance }
                        filterInteractionType={ filterInteractionType }
                    />
                );
            })}
        </React.Fragment>
    );
};

SingleSelectOptions.propTypes = {
    values: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    attribute: PropTypes.string.isRequired,
    selectedValues: PropTypes.array,
    appliedFiltersInfo: PropTypes.object,
    onSelectChange: PropTypes.func.isRequired,
    isPopular: PropTypes.bool
};

SingleSelectOptions.defaultProps = {
    appliedFiltersInfo: {},
    isPopular: false
};

export default SingleSelectOptions;
