import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { css as uiCss } from 'panamera-react-ui';

import LinkCheckbox from 'Components/FiltersTanak/components/LinkCheckbox.APP_TARGET';

import css from './MultiSelectOptions.desktop.scss';
import { isEmpty } from 'Helpers/objects';
import { TRACK_FILTER_TYPE } from 'Constants/filters';
const { customScrollbar: extraCss } = uiCss;

export const MultiSelectOptions = ({
    values,
    label,
    selectedValues,
    appliedFiltersInfo,
    onChange,
    className,
    groupName,
    showWithMargin,
    isPopular,
    isMX,
    renderLabelAs
}) => {
    const filterInteractionType = isPopular ? TRACK_FILTER_TYPE.POPULAR_LIST : TRACK_FILTER_TYPE.CUSTOM_LIST;

    return (
        <React.Fragment>
            <div className={ classnames(css.label, { [css.mgT16]: showWithMargin }, className) }>{label}</div>
            <div className={ classnames(css.multiSelectOptions, extraCss.customScrollbar) }>
                { values && values.map(({ abundance, value, name }) => {
                    if (value === '-1') {
                        return null;
                    }

                    const isChecked = !!(selectedValues && selectedValues.find(item => item === value));
                    const { formattedExactCount } = abundance || {};
                    const abundanceLabel = isEmpty(abundance) ? '' : `(${formattedExactCount})`;

                    return (<LinkCheckbox
                        id={ `f-${groupName}-${value}` }
                        appliedFiltersInfo={ appliedFiltersInfo }
                        attribute={ groupName }
                        value={ value }
                        isChecked={ isChecked }
                        key={ value }
                        labelTitle={ name }
                        onTap={ onChange }
                        name={ value }
                        isMultiSelect={ true }
                        abundanceLabel={ abundanceLabel }
                        filterInteractionType={ filterInteractionType }
                        isMX={ isMX }
                        renderLabelAs={ renderLabelAs }
                    />);
                })}
            </div>
        </React.Fragment>
    );
};

MultiSelectOptions.propTypes = {
    values: PropTypes.array,
    label: PropTypes.string.isRequired,
    selectedValues: PropTypes.array.isRequired,
    appliedFiltersInfo: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    groupName: PropTypes.string.isRequired,
    showWithMargin: PropTypes.bool,
    isPopular: PropTypes.bool,
    isMX: PropTypes.bool,
    renderLabelAs: PropTypes.oneOf(['span', 'link'])
};

MultiSelectOptions.defaultProps = {
    className: '',
    appliedFiltersInfo: {},
    onLinkCLick: () => {},
    showWithMargin: false,
    isPopular: false,
    isMX: false
};

export default MultiSelectOptions;
