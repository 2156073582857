import React from 'react';
import { compose } from 'redux';
import css from './Option.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'panamera-react-ui';
import { FACET_OPTION_TYPE } from 'Constants/filters';
import Icon from 'Components/ThematicIcon/ThematicIcon';

import withFilterURL from 'HOCs/withFilterURL/withFilterURL';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { isEmpty } from 'Helpers/objects';
import { FormattedMessage as Translation } from 'react-intl';
import { noop } from 'Helpers/function';

export const Option = ({
    onTap,
    onLinkCLick,
    isChecked,
    name,
    icon,
    imageURI,
    config,
    to,
    abundance,
    renderOptionAs
}) => {
    const optionAsDivContainer = renderOptionAs === FACET_OPTION_TYPE;
    const Wrapper = optionAsDivContainer ? FACET_OPTION_TYPE : Link;
    const { formattedRoundedCount, suffix, suffixLabelTranslation } = abundance;
    const totalItems = { totalItems: `${formattedRoundedCount}${suffix}` };
    const staticAssets = config.get('staticAssets');
    const finalImgURI = imageURI ? `${staticAssets}/${imageURI.replace('.webp', '')}` : null;
    let imageURIMap = null;

    if (finalImgURI) {
        imageURIMap = {
            '1x': finalImgURI.replace('$width$', '1x'),
            '2x': finalImgURI.replace('$width$', '2x'),
            '3x': finalImgURI.replace('$width$', '3x')
        };
    }

    return (
        <Wrapper
            className={ css.link }
            to={ optionAsDivContainer ? null : to }
            role="link"
            tabIndex={ 0 }
            onClick={ onLinkCLick }
        >
            <div
                className={ classNames(css.Option, { [css.checkedPopularLabel]: isChecked }) }
                onClick={ onTap }
            >
                <div className={ css.viewLabel }>
                    {icon
                        && <span className={ css.icon }>
                            <Icon
                                icon={ `view${icon}` }
                                size={ 16 }
                                color={ isChecked ? 'black' : 'blackSecondaryText' }
                            />
                        </span>
                    }
                    {imageURI && (
                        <span className={ css.imgHolder }>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcSet={ `${imageURIMap['1x']}.webp 360w,
                                    ${imageURIMap['2x']}.webp 720w,
                                    ${imageURIMap['3x']}.webp 1080w` }
                                />
                                <source
                                    srcSet={ `${imageURIMap['1x']}.png 360w,
                                ${imageURIMap['2x']}.png 720w,
                                ${imageURIMap['3x']}.png 1080w` }
                                />
                                <img
                                    className={ css.img }
                                    alt={ name }
                                    src={ `${imageURIMap['1x']}.png` }
                                />
                            </picture>
                        </span>
                    )}
                    <span className={ classNames(css.ellipsizedTxt, {
                        [css.isChecked]: isChecked
                    }) }>
                        {name}
                    </span>
                </div>
                <div>
                    {!isEmpty(abundance)
                        && <Translation id={ suffixLabelTranslation } values={ totalItems } />
                    }
                </div>
            </div>
        </Wrapper>
    );
};

Option.propTypes = {
    onTap: PropTypes.func.isRequired,
    onLinkCLick: PropTypes.func,
    to: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    imageURI: PropTypes.string,
    config: PropTypes.object.isRequired,
    abundance: PropTypes.shape({
        count: PropTypes.number,
        formattedExactCount: PropTypes.string,
        roundedCount: PropTypes.number,
        formattedRoundedCount: PropTypes.string,
        suffix: PropTypes.string,
        suffixLabelTranslation: PropTypes.string
    }),
    renderOptionAs: PropTypes.oneOf(['link', 'div'])
};

Option.defaultProps = {
    onLinkCLick: noop,
    abundance: {},
    renderOptionAs: 'link'
};

export default compose(
    withFilterURL,
    withConfig('config')
)(Option);
