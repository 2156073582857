import React from 'react';
import PropTypes from 'prop-types';
import { css as uiCss } from 'panamera-react-ui';
import classnames from 'classnames';
import { FixedSizeList as List } from 'react-window';
import debounce from 'lodash/debounce';
import SearchInput from './SearchInput/SearchInput';
import PopularOption from '../components/PopularOption.desktop';
import CommonOption from '../components/CommonOption.desktop';
import { getFilteredNestedSelect } from 'Helpers/filtersTanak';
import { selectionType as selectionConst, POPULAR_GRID_COL, SCROLL_DEBOUNCE_TIMER } from 'Constants/filters';
import { isEmpty } from 'Helpers/objects';
import { FILTERSV2_EVENTS } from 'Constants/tracking';
import { TRACK_FILTER_TYPE } from 'Constants/filters';

import css from './NestedSelect.desktop.scss';
import { convertArrayToGrid } from 'Helpers/array';
const { customScrollbar: extraCss } = uiCss;

class NestedSelect extends React.Component {
    static propTypes = {
        appliedFiltersInfo: PropTypes.object,
        filter: PropTypes.object,
        filtersInfo: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        staticAssets: PropTypes.string,
        trackFilters: PropTypes.func,
        isSetIntial: PropTypes.bool,
        isMX: PropTypes.bool
    }

    static defaultProps = {
        appliedFiltersInfo: {},
        filter: {},
        filtersInfo: {},
        staticAssets: '',
        trackFilters: () => {},
        isSetIntial: false,
        isMX: false
    }

    constructor() {
        super();
        this.state = {
            searchVal: '',
            open: false
        };
    }

    componentDidUpdate(prevProps) {
        const { isSetIntial } = this.props;

        if (isSetIntial && prevProps.isSetIntial !== isSetIntial) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ searchVal: '' });
        }
    }

    onChangeSearch = value => this.setState({
        searchVal: typeof value === 'string'
            ? value.toLowerCase()
            : ''
    });

    onSearchEmpty = () => this.setState({ searchVal: '' });
    onSearchFocus = () => this.props.trackFilters(FILTERSV2_EVENTS.SEARCH_START);
    onSearchBlur = () => this.props.trackFilters(FILTERSV2_EVENTS.SEARCH_COMPLETE, {
        search_user_query: this.state.searchVal
    })
    handleScroll = debounce(attribute => this.props.trackFilters(FILTERSV2_EVENTS.APPLIED_FILTERS_SCROLL, {
        select_from: attribute
    }), SCROLL_DEBOUNCE_TIMER);

    getPopularSelect(filterInfo, appliedFilterValues) {
        if (filterInfo.render?.popularConfiguration?.options?.length && !this.state.searchVal) {
            const { attribute, render: { popularConfiguration: { label, options, selectionType } = {}} = {}} = filterInfo;
            const values = [];
            const valuesInfo = options.reduce((acc, curr) => {
                values.push(curr.value);
                return { ...acc, [curr.value]: { ...curr }};
            }, {});
            const list = values.map(id => {
                const { value, name, imageURI } = valuesInfo[id];
                const isMultiSelect = selectionType === selectionConst.MULTIPLE;

                return (
                    <PopularOption
                        key={ `popular-${attribute}-${value}` }
                        appliedFiltersInfo={ this.props.appliedFiltersInfo }
                        attribute={ attribute }
                        name={ name }
                        value={ value }
                        imageURI={ imageURI }
                        isMultiSelect={ isMultiSelect }
                        isChecked={ appliedFilterValues[id] }
                        staticAssets={ this.props.staticAssets }
                        onTap={ this.handleChange }
                        filterInteractionType={ TRACK_FILTER_TYPE.POPULAR_GRID }
                    />
                );
            });

            return (
                <div className={ css.selectOptions }>
                    <div className={ classnames(css.selectLabel, css.popularLabel) }>{label}</div>
                    <div className={ css.popularContainer }>
                        {
                            convertArrayToGrid(list, POPULAR_GRID_COL).map((row, idx) => {
                                return <div key={ `popular-grid-row-${idx}` } className={ css.popularRow }>{row}</div>;
                            })
                        }
                    </div>
                </div>
            );
        }

        return null;
    }

    handleChange = (...args) => {
        const meta = args[args.length - 1];

        if (this.state.searchVal) {
            meta.filterInteractionType = TRACK_FILTER_TYPE.SEARCH;
        }

        this.props.onChange(meta);
    }

    getCommonSelect(filterInfo, appliedFilterValues) {
        const { appliedFiltersInfo } = this.props;
        const {
            attribute,
            render: {
                customConfiguration: { label, values, valuesInfo, selectionType } = {}
            } = {}
        } = filterInfo;

        const onScrollSection = () => this.handleScroll(attribute);
        // #todo: Remove when nestedValues key of nested-select filter is rectified
        const commonChoices = values ? values.filter(opt => !!valuesInfo[opt]) : [];

        const Row = ({ index, style }) => {
            const id = commonChoices[index];

            if (!valuesInfo[id]) {
                return null;
            }

            return (
                <CommonOption
                    key={ `common-${attribute}-${valuesInfo[id].value}` }
                    name={ valuesInfo[id].name }
                    value={ valuesInfo[id].value }
                    imageURI={ valuesInfo[id].imageURI }
                    isMultipleChoice={ selectionType === selectionConst.MULTIPLE }
                    groupName={ attribute }
                    isApplied={ appliedFilterValues[id] }
                    appliedFiltersInfo={ appliedFiltersInfo }
                    onChange={ this.handleChange }
                    abundance={ valuesInfo[id].abundance }
                    filterInteractionType={ TRACK_FILTER_TYPE.CUSTOM_LIST }
                    style={ style }
                    isMX={ this.props.isMX }
                />
            );
        };

        if (valuesInfo && values && values.length) {
            const listStyle = { height: 'auto' };

            return (
                <div className={ css.selectOptions }>
                    <div className={ css.selectLabel }>{label}</div>
                    {
                        <List
                            height={ 196 }
                            itemCount={ commonChoices.length }
                            itemSize={ 32 }
                            className={ classnames(css.choiceContent, extraCss.customScrollbar) }
                            // eslint-disable-next-line react/jsx-no-bind
                            onScroll={ onScrollSection }
                            style={ listStyle }
                        >
                            { Row }
                        </List>
                    }
                </div>
            );
        }

        return null;
    }

    getSelectFilterUI = ({ nestedFilterAttr, ...filter }, filterInfo) => {
        const { appliedFiltersInfo } = this.props;
        const appliedFilter
            = appliedFiltersInfo[filter.attribute] ? appliedFiltersInfo[filter.attribute]
                .reduce((acc, filter) => ({ ...acc, [filter]: true }), {}) : {};

        if (nestedFilterAttr) {
            return (
                <React.Fragment>
                    {this.getPopularSelect(filter, appliedFilter)}
                    {this.getCommonSelect(filter, appliedFilter)}
                    {this.getSelectFilterUI(filterInfo[nestedFilterAttr] || {}, filterInfo)}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this.getPopularSelect(filter, appliedFilter)}
                {this.getCommonSelect(filter, appliedFilter)}
            </React.Fragment>
        );
    }

    render() {
        const { filter, filtersInfo, appliedFiltersInfo } = this.props;
        const { searchVal } = this.state;

        if (filter && filter.render) {
            const tranformedFilterInfo = getFilteredNestedSelect(filter, filtersInfo, searchVal, appliedFiltersInfo);

            return (
                <React.Fragment>
                    {
                        filter.render.searchConfiguration
                        && filter.render.searchConfiguration.label
                        && <SearchInput
                            key={ `search-${filter.attribute}` }
                            className={ css.searchInput }
                            placeholder={ filter.render.searchConfiguration.label }
                            setSearchCriteria={ this.onChangeSearch }
                            searchValue={ this.state.searchVal }
                            onSearchEmpty={ this.onSearchEmpty }
                            onFocus={ this.onSearchFocus }
                            onBlur={ this.onSearchBlur }
                            isMX={ this.props.isMX }
                        />
                    }
                    {!isEmpty(tranformedFilterInfo) && this.getSelectFilterUI(tranformedFilterInfo[filter.attribute], tranformedFilterInfo)}
                </React.Fragment>
            );
        }
        return null;
    }
}

export default NestedSelect;
