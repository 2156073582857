import React from 'react';
import PropTypes from 'prop-types';
import { TRACK_FILTER_TYPE } from 'Constants/filters';
import MultiSelectOptions from './MultiSelectOptions.APP_TARGET';

class MultiSelectFilter extends React.PureComponent {
    static propTypes = {
        attribute: PropTypes.string.isRequired,
        popularConfiguration: PropTypes.object,
        customConfiguration: PropTypes.object.isRequired,
        selectedValues: PropTypes.array,
        appliedFiltersInfo: PropTypes.object,
        onSelectChange: PropTypes.func.isRequired,
        isMX: PropTypes.bool
    }

    static defaultProps = {
        isMX: false
    }

    handleChange = (value, key, meta) => {
        const { selectedValues, onSelectChange, attribute } = this.props;
        let newSelectedValues = selectedValues ? [...selectedValues] : [];

        if (value) {
            newSelectedValues.push(key);
        }
        else {
            newSelectedValues = newSelectedValues.filter(item => item !== key);
        }

        onSelectChange(attribute, newSelectedValues, TRACK_FILTER_TYPE.CUSTOM_LIST, meta);
    }

    render() {
        const {
            selectedValues,
            appliedFiltersInfo,
            popularConfiguration,
            customConfiguration,
            attribute
        } = this.props;

        return (
            <React.Fragment>
                {popularConfiguration
                    && <MultiSelectOptions
                        values={ popularConfiguration.options }
                        label={ popularConfiguration.label }
                        selectedValues={ selectedValues }
                        appliedFiltersInfo={ appliedFiltersInfo }
                        onChange={ this.handleChange }
                        groupName={ attribute }
                        isPopular={ true }
                        isMX={ this.props.isMX }
                        renderLabelAs={ customConfiguration?.renderLabelAs || null }
                    />
                }
                <MultiSelectOptions
                    values={ customConfiguration.values }
                    label={ customConfiguration.label }
                    selectedValues={ selectedValues }
                    appliedFiltersInfo={ appliedFiltersInfo }
                    onChange={ this.handleChange }
                    showWithMargin={ !!popularConfiguration }
                    groupName={ attribute }
                    isPopular={ false }
                    isMX={ this.props.isMX }
                    renderLabelAs={ customConfiguration?.renderLabelAs || null }
                />
            </React.Fragment>
        );
    }
}

export default MultiSelectFilter;
