import React from 'react';
import PropTypes from 'prop-types';
import SingleSelectOptions from './SingleSelectOptions';

const SingleSelectFilter = ({
    customConfiguration,
    popularConfiguration,
    attribute,
    selectedValues,
    appliedFiltersInfo,
    onSelectChange
}) => {
    return (
        <React.Fragment>
            {popularConfiguration
                && <SingleSelectOptions
                    values={ popularConfiguration.options }
                    label={ popularConfiguration.label }
                    attribute={ attribute }
                    selectedValues={ selectedValues }
                    appliedFiltersInfo={ appliedFiltersInfo }
                    onSelectChange={ onSelectChange }
                    isPopular={ true }
                />
            }
            <SingleSelectOptions
                values={ customConfiguration.values }
                label={ customConfiguration.label }
                attribute={ attribute }
                selectedValues={ selectedValues }
                appliedFiltersInfo={ appliedFiltersInfo }
                onSelectChange={ onSelectChange }
                isPopular={ false }
            />

        </React.Fragment>
    );
};

SingleSelectFilter.propTypes = {
    popularConfiguration: PropTypes.object,
    customConfiguration: PropTypes.object.isRequired,
    attribute: PropTypes.string.isRequired,
    selectedValues: PropTypes.array,
    appliedFiltersInfo: PropTypes.object,
    onSelectChange: PropTypes.func.isRequired
};

SingleSelectFilter.defaultProps = {
    appliedFiltersInfo: {}
};

export default SingleSelectFilter;
